@font-face {
  font-family: RailGrotesk;
  src: url(../../Fonts/RailGrotesk/RailGrotesk-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: NanoTech;
  src: url(../../Fonts/NanotechLLC/NanoTech-Regular.otf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: NanoTechItalic;
  src: url(../../Fonts/NanotechLLC/Nanotech-NormalItalic.otf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: NeueMachina;
  src: url(../../Fonts/NeueMachina/NeueMachina-Regular.otf);
  font-style: normal;
  //   font-weight: 400;
}
