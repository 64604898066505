.careersLeftSvg1 {
  position: absolute;
  z-index: 2;
  width: 61%;
}
.careersLeftSvg2 {
  position: absolute;
  z-index: 1;
  left: 0px;
  bottom: 0px;
}

.careersArea {
  display: grid;
  grid-template-columns: 33.333% 33.333% 33.333%;
  grid-template-rows: auto auto auto;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.careerDiv {
  flex-wrap: wrap;
  font-size: 14px;
  color: white;
  font-family: NeueMachina;
  border: 1px solid #3d4544;
  display: flex;
  justify-content: left;
  width: 1/3;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px 10% 44px;
}

.careerDiv li {
  list-style-type: none;
  font-family: NeueMachina;
  font-size: 14px;
  line-height: 20px;
}

/* --------
 Typography

---------- */

.title {
  font-size: 34px;
  font-family: NeueMachina;
  font-weight: 400;
  color: white;
}

.careers-body__right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.careerBtn {
  width: auto;
  height: 26px;
  outline: none;
  overflow: hidden;
  border: none;
  color: white;
  background-color: #131c1b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s;
}

.careerBtn:hover,
.sendResumeBtn:hover,
.backButton:hover {
  color: #19a79e;
}

.buttonDiv {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  left: 0px;
  margin-top: 15%;
  z-index: 1;
  position: relative;
}

.mailDiv {
  z-index: 1;
}

.resumeDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.careerBtn,
.backButton,
.sendResumeBtn {
  width: auto;
  height: 26px;
  outline: none;
  overflow: hidden;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #131c1b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s;
}

.resumeMail {
  padding: 13px;
  outline: none;
  border: 0px;
  width: 77%;
}
input[type="text"] {
  font-size: 17px;
}

.mailInput {
  padding: 7px;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 3px;
}

.message {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 0px;
  top: 0px;
  background-color: #131c1b;
  z-index: 2;
}

.listDiv {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

@media (max-width: 1000px) {
  .careersArea {
    display: grid;
    justify-content: center;
    grid-template-columns: 100%;
  }
  .careerDiv {
    align-items: center;
    padding: 15px 0px 10% 0px;
    height: 470px;
  }

  .resumeDiv {
    align-items: center;
  }

  .listDiv {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }
}
.fileDiv {
  height: 25%;
  width: 193px;
  border: 2px dashed #ffffff;
  background-color: #131c1b;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  word-break: break-word;
  padding: 23px;
  transition: 0.3s;
}
.dragFileHere {
  font-size: 15px;
  transition: 0.5s;
  font-family: NeueMachina;
}

@media (max-width: 1200px) {
  .careers-body__right {
    justify-content: flex-start;
  }

  .careers__title {
    :last-child {
      margin-right: 30px;
    }
  }
}

.nonDisplay {
  display: none;
}

.resumeList {
  display: none;
}

.dragInputDiv {
  height: 35%;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  opacity: 0;
}

.dragInput {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 5;
  left: 0px;
  top: 0px;
  opacity: 0;
}
.forOpacity {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  background-color: #48bfae;
  left: 0px;
  top: 0px;
  transition: 0.5s;
}

.errorMail {
  border: 1px solid red;
}

.mailErrorMesage {
  color: red;
  position: absolute;
}

.hideX {
  overflow-x: hidden;
}
