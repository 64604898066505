/* --------
 Typography
---------- */

.service--body-text {
  white-space: nowrap;
  h3 {
    font-size: 2.3rem;
    color: #e6e6e6;
    font-family: NeueMachina;
    margin: 0;
    font-weight: regular;
    letter-spacing: 0.5px;
  }
  ul {
    padding: 0;
    list-style-type: none;
    li {
      opacity: 70%;
      font-size: 1.3rem;
      color: #e6e6e6;
      font-family: NeueMachina;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.4px;
    }
  }
}
.service_fullDiv {
  display: flex;
  flex-direction: row;
}

.service_logo {
  width: 23px;
  height: 23px;
  margin-right: 10px;
}

@media (max-width: 1700px) {
  .service--body-text {
    h3 {
      font-size: 2.8rem;
      font-family: NeueMachina;
    }
    ul {
      li {
        font-size: 1.7rem;
        font-family: NeueMachina;
      }
    }
  }
}

@media (max-width: 1200px) {
  .service--body-text {
    h3 {
      font-size: 2.5rem;
    }
    ul {
      font-size: 2rem;
    }
  }
}

/* --------
 LayOuts
---------- */
.flex-services {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 900px) {
  .flex-services {
    overflow-y: auto;
  }
}
.services-body__right {
  display: grid;
  grid-template-columns: 1.4fr 1fr 1fr;
  padding: 50px 50px 52px 44px;
  grid-template-rows: auto;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
}

@media (max-width: 1200px) {
  .services-body__right {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 100px 100px 100px;
    grid-template-columns: 1fr 1fr 0.6fr;
  }
}

@media (max-width: 1000px) {
  .flex-services {
    align-items: center;
  }
  .services-body__right {
    padding: 40px;
    grid-template-columns: 1fr 1fr 0.8fr;
    grid-template-rows: auto;
    grid-column-gap: 35px;
    grid-row-gap: 15px;
    padding-top: 20px;
  }

  .services-title__mobile {
    grid-column: 1/-1;
    justify-self: center;
  }
}

@media (max-width: 700px) {
  .service_logo {
    margin-top: 25px;
  }

  .flex-services {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .services-body__right {
    visibility: hidden;
    h3 {
      font-size: 26px;
      a {
        margin: 0 auto;
      }
    }
    ul {
      li {
        font-size: 20px;
        line-height: 2.3;
      }
    }
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    height: 75%;
    width: 100%;
  }

  .service--body-text {
    white-space: normal;
    margin-top: 25px;
  }

  .services-title__mobile {
    grid-column: 1/-1;
    justify-self: center;
    margin-top: 0px;
  }
}

// services HeadIcon

.services-head__icon {
  position: absolute;
  left: 0;
  top: 55%;
}

//services scroll helper
.services__mobile--SVG {
  display: none;
  height: 100%;
  width: 100vw;
  background-color: #19a79d;
}

.services__mobile--SVGG {
  height: 100%;
  width: 100%;
}

@media (max-width: 700px) {
  .services__mobile--SVG {
    display: block;
    position: absolute;
    z-index: 1;
  }

  .services__mobile--SVGG-swipe {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 30px;
    margin-bottom: 19px;
  }
}

.servicesSvg1 {
  position: absolute;
  pointer-events: none;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  opacity: 30%;
}

.servicesSvg1 image {
  animation: serviceSvg 10s ease-in-out;
}

@keyframes serviceSvg {
  from {
    width: 0px;
  }
  to {
    width: auto;
  }
}
