/* --------
 Typography
---------- */

/* --------
left Body Area
---------- */

.project-body__left {
  position: relative;
}

.project__helicopter {
  position: absolute;
  bottom: 0;
  left: 0;
}

.project__img {
  width: 677px;
  height: 318px;
}

.project__helicopter {
  width: 35%;
}

.projectMobileHeaderDiv {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    height: 35px;
  }
}

.visit__website {
  display: flex;

  a {
    margin-top: 5px;
    font-size: 20px;
    font-family: NeueMachina;
  }
}

#splide__listMobile {
  .visit__website {
    margin-top: 60px !important;
  }
}

.projects__mobile--SVG {
  z-index: 10;
  position: absolute;
  overflow: hidden;
  display: none;
  height: 100%;
  width: 100vw;
  background-color: #19a79d;
}

.projects__mobile--SVGG {
  height: 100%;
  width: 400px;
}

.projects__mobie--SVGG {
  height: 100% !important;
  width: 100% !important;
}

.swiper-container {
  height: 90%;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20%;
  align-items: center;
}

.swiper-mobile__container {
  display: none;
}

@media (max-width: 1200px) {
  // .swiper-slide {
  //   justify-content: center;
  // }

  .project__helicopter {
    display: none;
  }
}

/* ---------
  splide
 ----------*/
@mixin splide {
  width: 100%;
  height: 100%;
  color: #e6e6e6;

  h4 {
    font-size: 2.8rem;
    font-family: NeueMachina;
    margin: 0;
    display: inline-block;
    font-weight: 400;
  }

  span {
    font-size: 2.8rem;
    font-family: NeueMachina;
    font-weight: 400;
  }

  p {
    font-family: NeueMachina;
    font-weight: 400;
    font-size: 1.7rem;
    font-weight: 400;
    margin-top: 5vh;
  }

  .visit__website {
    margin-top: 5vh;
    white-space: nowrap;
  }

  .footer {
    font-family: NeueMachina;
    font-size: 1.7rem;
    margin-top: 5vh;
    font-weight: 400;
  }

  .index {
    position: relative;
    left: 0px;
    top: 21px;
    font-weight: 400;
    height: 48px;
    margin-right: 10px;
  }

  .splide__track {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .splide__slide {
    position: relative;
    margin-left: 3.55%;
  }
}

.swiper-wrapper {
  @include splide;
  margin-left: 20px;

  li {
    div {
      max-width: 609px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .visit__website {
    cursor: pointer;
    a {
      &:hover {
        color: #19a79e;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .swiper-wrapper {
    height: 80%;
  }
}

@media only screen and (max-width: 700px) {
  .swiper-mobile__container {
    display: flex;
    align-items: center;
    height: 67%;
  }

  .swiper__lg {
    display: none;
  }

  .project-body__right {
    visibility: hidden;
  }

  .splideMobile {
    display: block;
  }

  .splide {
    display: none;
  }
  .projects__mobile--SVG {
    display: block;
    position: absolute;
  }

  .projects__mobile--SVGG-swipe {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 30px;
    margin-bottom: 19px;
  }

  .index {
    top: 30px !important;
    left: -30px !important;
  }

  .swiper__mobile {
    li {
      div {
        width: 70% !important;
      }
    }
  }
}

@media only screen and (max-width: 400px) and (max-height: 600px) {
  .visit__website {
    img {
      height: 25px;
    }
    a {
      font-size: 15px;
    }
  }
}

.rocket {
  right: 0px;
  bottom: 0px;
  position: absolute;
  color: #19a79d;
  opacity: 30%;
}
