/* --------
 Typography
---------- */
.aboutUs-body-text {
  font-size: 18px;
  color: #e6e6e6;
  line-height: 24px;
  font-family: NeueMachina;
  padding: 0px 44px 0px 44px;
  max-height: 100%;
  overflow-y: auto;
  font-weight: 400;
}

.aboutUs_body_values {
  position: absolute;
  width: 100%;
  height: 267px;
  bottom: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.abVal {
  color: white;
  position: absolute;
  font-family: NeueMachina;
  font-size: 3rem;
}

.abVal1 {
  top: 9%;
  left: 22%;
}

.abVal2 {
  right: 24%;
  top: 3%;
}
.abVal3 {
  top: 39%;
  left: 9%;
}

.abVal5 {
  bottom: 21%;
  right: 13%;
}
.abVal6 {
  bottom: -7%;
  left: 20%;
}
.abVal7 {
  right: 39%;
  bottom: -3%;
}

.abVal8 {
  right: 4%;
  bottom: -10%;
}

.bug {
  width: 136px;
  height: 121px;
  position: absolute;
  bottom: 15px;
  left: 83px;
}

@media (max-width: 1700px) {
  .abVal {
    font-family: NeueMachina;
    font-size: 4rem;
  }
}

@media (max-width: 1500px) {
  .abVal {
    font-size: 3.3rem;
  }

  .abVal1 {
    top: 28%;
    left: 22%;
  }

  .abVal2 {
    right: 27%;
    top: 22%;
  }
  .abVal3 {
    top: 47%;
  }

  .abVal5 {
    bottom: 26%;
    right: 13%;
  }
  .abVal6 {
    bottom: 2%;
    left: 21%;
  }
  .abVal7 {
    right: 38%;
    bottom: 7%;
  }

  .abVal8 {
    right: 4%;
    bottom: -2%;
  }
}

@media (max-width: 1200px) {
  .aboutUs-body-text {
    font-size: 2rem;
    height: 79%;
  }
  .abVal {
    font-size: 4rem;
  }
}

/* --------
Body Area
---------- */

.aboutUs-body-text {
  overflow-y: auto;
}

@media (max-width: 1000px) {
  .aboutUs-body-text {
    font-size: 18px;
    color: #e6e6e6;
    line-height: 24px;
    font-family: NeueMachina;
    padding: 0px 44px 0px 44px;
    max-height: 100%;
    overflow-y: auto;
    font-weight: 400;
  }

  .aboutUs_body_values {
    position: absolute;
    width: 677px;
    height: 211px;
    bottom: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .abVal {
    font-size: 4rem;
  }

  .abVal1 {
    top: 12%;
    left: 18%;
  }

  .abVal2 {
    right: 20%;
    top: 9%;
  }
  .abVal3 {
    top: 41%;
    left: -3%;
  }

  .abVal5 {
    bottom: 14%;
    right: 4%;
  }
  .abVal6 {
    bottom: -15%;
    left: 13%;
  }
  .abVal7 {
    right: 38%;
    bottom: -2%;
  }

  .abVal8 {
    right: -6%;
    bottom: -16%;
  }
}

.aboutUs__image {
  margin-bottom: 30%;
}

.aboutUs-body__right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .aboutUs-body__right {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 800px) {
  .aboutUs_body_values {
    display: none;
  }
}

@media screen and (max-height: 650px) {
  .aboutUs_body_values {
    bottom: -15px;
  }
}
@media screen and (max-height: 600px) {
  .aboutUs_body_values {
    display: none;
  }
}
