/* --------
 Typography

---------- */

.blogsArea {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  width: 100%;
  // justify-content: space-between;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-wrap: wrap;
  position: relative;
}
.oneBlogArea {
  grid-template-columns: 100%;
  position: relative;
}

.title {
  font-size: 34px;
  font-family: NeueMachina;
  font-weight: bolder;
  color: white;
}

.glass {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  margin-top: 53%;
  margin-left: -77%;
}
.glass1 {
  position: fixed;
  z-index: 2;
  height: 70%;
  width: 70%;
  right: -17%;
  margin-top: 37%;
  opacity: 10%;
  pointer-events: none;
}

.learnToCode {
  position: absolute;
  z-index: 2;
  width: 55%;
}

.blog {
  flex-wrap: wrap;
  font-size: 14px;
  overflow-y: auto;
  color: #e6e6e6;
  font-family: NeueMachina;
  padding-right: 20%;
  line-height: 24px;
  font-weight: 100;
}

@media (max-width: 1200px) {
  .blog {
    font-size: 2rem;
  }
}

/* --------
  Body Area
  ---------- */

@media (max-width: 1000px) {
  .blogs {
    height: 68%;
  }
}

.blogs__image {
  margin-bottom: 30%;
}

.blogs-body__right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .blogs-body__right {
    justify-content: flex-start;
  }

  .blogs__title {
    :last-child {
      margin-right: 30px;
    }
  }
}

.moreText {
  display: none;
}

.blogDiv {
  border: 1px solid #3d4544;
  padding: 10px;
  padding-left: 44px;
}

.blogDiv.moreText .dots {
  display: none;
}
.blogDiv.readMore .moreText {
  display: inline;
}
.readMore {
  height: fit-content;
  width: 100%;
  position: relative;
}

.nonDisplay {
  display: none;
}

.blogBtn {
  width: 133px;
  height: 26px;
  outline: none;
  overflow: hidden;
  border: none;
  color: white;
  background-color: #131c1b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
}
.blogBtn:hover {
  color: #19a79e;
}

.buttonDiv {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 1000px) {
  .blogsArea {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    height: 100vh;
    position: relative;
    overflow-y: scroll;
    overflow-x: scroll;
  }
}
