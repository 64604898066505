/* -------- 
 Typography
---------- */
.header_ozorix-logo {
  width: 161px;
  margin-left: 74px;
}

.home-header__navigation {
  align-items: center;
  ul {
    list-style-type: none;
    align-items: center;
    li {
      a {
        text-decoration: none;
        color: #e6e6e6;
        font-size: 25px;
        font-family: NeueMachina;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}

/* -------- 
 Header Area  
---------- */

.home__header {
  display: flex;
  height: 126px;
  border-top: 1px solid #bebcbc4d;
  border-bottom: 1px solid #bebcbc4d;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .home-header__navigation {
    ul {
      display: flex;
    }

    img {
      opacity: 0;
    }

    .nav-hover--right {
      margin-right: 51px;
      // height: 2.5rem;
    }

    // .nav-hover-left {
    //   height: 2.5rem;
    // }

    .nav-last-hover {
      margin-right: 57px;
    }
  }
}

.home__menu {
  display: none;
}

.home-big__img {
  width: 1449.001px;
  height: 380.019px;
}

@media (min-width: 700px) {
  .home__menu {
    cursor: pointer;
  }
}

/* -------- 
 Body Area
---------- */

.home-small__img {
  display: none;
}

.home__body {
  display: flex;
  height: calc(100% - 126px);
  justify-content: center;
  align-items: center;
  // position: absolute;
  z-index: 5;
}

/* -------- 
 MEDIA
---------- */

@media (max-width: 1600px) {
  .home-big__img {
    width: 800px;
  }
}

@media (max-width: 1500px) {
  .home__header {
    .home-header__navigation {
      .nav-hover--right {
        margin-right: 35px;
      }
    }
  }
}

@media (max-width: 1850px) {
  .home-header__navigation {
    ul {
      li {
        a {
          font-size: 2.2rem;
        }
      }
    }
  }
}

@media (max-width: 2000px) {
  .nav-hover--right {
    height: 2.5rem;
  }

  .nav-hover-left {
    height: 2.5rem;
  }
}

@media (max-width: 1250px) {
  .home__menu {
    display: flex;
    padding-right: 30px;
  }
  .home {
    .home__header {
      .home-header__navigation {
        display: none;
      }
    }
  }
}

@media (max-width: 950px) {
  .home-big__img {
    // display: none;
    display: block;
    width: 600px;
  }
}

@media (max-width: 700px) {
  .header_ozorix-logo {
    margin-left: 30px;
    width: 130px;
  }

  .home-big__img {
    // display: none;
    display: block;
    width: 445px;
  }
}

@media (max-width: 450px) {
  .home-big__img {
    width: 380px;
  }
}

@media (max-width: 400px) {
  .home-big__img {
    width: 300px;
  }
}

@media (max-width: 300px) {
  .home-big__img {
    width: 280px;
  }
}

.home-hover,
.aboutUs-hover,
.services-hover,
.projects-hover,
.blogs-hover,
.careers-hover,
.customers-hover,
.contact-hover {
  transition: 0.5s;
}
